import { ElementType, forwardRef, HTMLAttributes, useMemo } from 'react'
import classNames from 'classnames'
import parse from 'html-react-parser'

import { nl2br, removeParagraph } from '@/utils/strings'

export const TypographyVariants = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'large',
  'regular',
  'small',
  'caption',
  'overline',
  'menu'
] as const

export type TypographyVariant = (typeof TypographyVariants)[number]

export type TypographyElement =
  | HTMLHeadingElement
  | HTMLParagraphElement
  | HTMLSpanElement

export type TypographyProps = HTMLAttributes<TypographyElement> &
  Partial<{
    component: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span'
    paragraph: boolean
    strong: boolean
    variant: TypographyVariant
  }>

const Typography = forwardRef<TypographyElement, TypographyProps>(
  (
    {
      variant = 'regular',
      component,
      paragraph,
      className,
      children,
      strong,
      ...props
    },
    ref
  ) => {
    const variantClasses = useMemo(
      () => ({
        h1: 'font-playfair text-s-h1 md:text-d-h1 -mt-[.2em] -mb-[.1em]',
        h2: 'font-playfair text-s-h2 md:text-d-h2 -mt-[.2em] -mb-[.1em]',
        h3: 'font-playfair text-s-h3 md:text-d-h3 -mt-[.2em] -mb-[.1em]',
        h4: 'font-playfair text-s-h4 md:text-d-h4 -mt-[.3em] -mb-[.2em]',
        h5: 'font-playfair text-s-h5 md:text-d-h5 -mt-[.3em] -mb-[.1em]',
        h6: 'font-playfair text-s-h6 md:text-d-h6 -mt-[.3em] -mb-[.2em]',
        large: classNames('font-roboto text-large -mt-[.3em] -mb-[.35em]', {
          'font-normal': strong
        }),
        regular: classNames('font-roboto text-regular -mt-[.3em] -mb-[.35em]', {
          'font-normal': strong
        }),
        small: classNames('font-roboto text-small -mt-[.3em] -mb-[.35em]', {
          'font-normal': strong
        }),
        caption: classNames('font-roboto text-caption -mt-[.3em] -mb-[.35em]', {
          'font-normal': strong
        }),
        overline: 'font-montserrat text-overline uppercase -my-[.45em]',
        menu: 'font-montserrat text-menu uppercase -mt-[.4em] -mb-[.45em]'
      }),
      [strong]
    )

    const innerClasses = classNames(
      '[&_a]:text-text-overline-light [&_a]:pointer:hover:opacity-70 [&_a]:transition-opacity [&_a]:duration-300 [&_a]:ease-out-cubic',
      '[&_ul]:list-disc [&_ul]:pl-[1.45rem] [&_ul>br]:hidden',
      '[&_ol]:list-decimal [&_ol]:pl-[1.85rem] [&_ol>br]:hidden',
      '[&_strong]:font-bold',
      '[&_b]:font-bold'
    )

    const Component = (component || (paragraph ? 'p' : 'span')) as ElementType

    return (
      <Component
        ref={ref}
        className={classNames(
          (variantClasses as any)[variant],
          innerClasses,
          className
        )}
        {...props}
      >
        {parse(children ? removeParagraph(nl2br(children as string)) : '')}
      </Component>
    )
  }
)

export default Typography
