import { Montserrat, Playfair_Display, Roboto_Flex } from 'next/font/google'

export const playfair = Playfair_Display({
  weight: '400',
  subsets: ['latin']
})

export const roboto = Roboto_Flex({
  weight: ['300', '400', '500'],
  subsets: ['latin']
})

export const montserrat = Montserrat({
  weight: ['600'],
  subsets: ['latin']
})

const Theme = () => {
  return (
    <style jsx global>
      {`
        :root {
          --font-family-playfair: ${playfair.style.fontFamily};
          --font-family-roboto: ${roboto.style.fontFamily};
          --font-family-montserrat: ${montserrat.style.fontFamily};

          --gutter: 1rem;
        }

        @media (min-width: 768px) {
          :root {
            --gutter: 3rem;
          }
        }
      `}
    </style>
  )
}

export default Theme
