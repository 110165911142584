export const stripSlash = (url: string) => url.replace(/^\/|\/$/g, '')

export const isExternalLink = (url: string) => {
  if (typeof window !== 'undefined') {
    const tmp = document.createElement('a')
    tmp.href = url
    const external = tmp.host !== window.location.host
    const whitelist: string[] = []
    const isWhitelisted = whitelist.some((domain) => tmp.host.endsWith(domain))
    const isPdfOrJpg =
      tmp.pathname.toLowerCase().endsWith('.pdf') ||
      tmp.pathname.toLowerCase().endsWith('.jpg')
    return (external && !isWhitelisted) || isPdfOrJpg
  }
}
