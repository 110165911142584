import { forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'

const Grid = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={classNames(
          'gap-x-gutter grid h-auto w-full grid-cols-2 md:grid-cols-6 lg:grid-cols-12',
          className
        )}
        {...props}
      />
    )
  }
)

export default Grid
