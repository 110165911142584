import { merge } from 'lodash'
import { create } from 'zustand'
import {
  createJSONStorage,
  persist,
  subscribeWithSelector
} from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

type State = {
  needsAgeGate?: boolean
}

type Actions = {
  // eslint-disable-next-line no-unused-vars
  setState: (state: Partial<State>) => void
}

const initialState: State = {
  needsAgeGate: true
}

const useAgeGateStore = create(
  persist(
    subscribeWithSelector(
      immer<State & Actions>((set) => ({
        ...initialState,
        setState: (state) => set((prevState) => merge(prevState, state))
      }))
    ),
    {
      name: 'age-gate', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage) // (optional) by default, 'localStorage' is used
    }
  )
)

export { useAgeGateStore }
