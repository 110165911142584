import axios from 'axios'

import { API_BASE_URL, FRONT_BASE_URL } from '@/constants'

const axiosServer = axios.create({ baseURL: API_BASE_URL })
const axiosClient = axios.create({ baseURL: FRONT_BASE_URL })

axiosClient.defaults.headers.common['Env'] =
  process.env.NODE_ENV === 'development' ? 'development' : 'production'

export { axiosClient, axiosServer }
