import { camelCase, isArray, isObject, snakeCase, transform } from 'lodash'

export const transformKeysToSnakeCase = (object: any) =>
  transform<any, any>(object, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : snakeCase(key as string)
    acc[camelKey] = isObject(value) ? transformKeysToSnakeCase(value) : value
  })

export const transformKeysToCamelCase = (object: any) =>
  transform<any, any>(object, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : camelCase(key as string)
    acc[camelKey] = isObject(value) ? transformKeysToCamelCase(value) : value
  })
