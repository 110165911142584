import { forwardRef, PropsWithChildren } from 'react'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'

export type LinkProps = PropsWithChildren<
  NextLinkProps & { className?: string; tabIndex?: number }
>

const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  return <NextLink ref={ref} scroll={false} {...props} />
})

export default Link
