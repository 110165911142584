import { merge } from 'lodash'
import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

type State = {
  pageLoaded?: boolean
}

type Actions = {
  // eslint-disable-next-line no-unused-vars
  setState: (state: Partial<State>) => void
}

const initialState: State = {
  pageLoaded: false
}

const useLoaderStore = create(
  subscribeWithSelector(
    immer<State & Actions>((set) => ({
      ...initialState,
      setState: (state) => set((prevState) => merge(prevState, state))
    }))
  )
)

export { useLoaderStore }
