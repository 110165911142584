import { createContext, PropsWithChildren, useRef } from 'react'
import { createStore, StoreApi } from 'zustand'
import { devtools } from 'zustand/middleware'

import type { WPMenu } from '@/types'

export type Store = {
  strings?: Record<string, any>
  menu?: {
    mainMenu: WPMenu

    headerMenu01: WPMenu
    headerMenu02: WPMenu
    headerMenu03: WPMenu
    headerMenu04: WPMenu

    footerMenu01: WPMenu
    footerMenu02: WPMenu
    footerMenu03: WPMenu
    footerMenu04: WPMenu

    socialMenu: WPMenu
    logosMenu: WPMenu
  }
}

export const StoreContext = createContext<StoreApi<Store> | undefined>(
  undefined
)

const getInitialState = (state: Store) => {
  return createStore(devtools<Store>(() => ({ ...state })))
}

const StoreProvider = ({ children, ...props }: PropsWithChildren<Store>) => {
  const storeRef = useRef<StoreApi<Store> | undefined>(undefined)

  if (!storeRef.current) {
    storeRef.current = getInitialState(props)
  }

  return (
    <StoreContext.Provider value={storeRef.current}>
      {children}
    </StoreContext.Provider>
  )
}

export default StoreProvider
