import { forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'

const Container = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={classNames('container', className)}
        {...props}
      />
    )
  }
)

export const ContainerFluid = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <Container
      ref={ref}
      className={classNames(className, 'max-w-full')}
      {...props}
    />
  )
})

export default Container
