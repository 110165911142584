import { HTMLAttributes } from 'react'

type SVGIconProps = HTMLAttributes<SVGElement> & { symbol?: string }

const SVGIcon = ({ symbol, ...props }: SVGIconProps) => {
  return (
    <svg {...props}>
      <use xlinkHref={`#${symbol}`} />
    </svg>
  )
}

export default SVGIcon
