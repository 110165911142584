import { HTMLAttributes } from 'react'
import classNames from 'classnames'

export type SpinnerProps = HTMLAttributes<HTMLDivElement> & {
  variant?: 'small' | 'medium'
}

const Spinner = ({ className, variant = 'medium', ...props }: SpinnerProps) => {
  return (
    <span
      className={classNames(
        'block animate-rotate',
        {
          'h-[1.6rem] w-[1.6rem]': variant === 'small',
          'h-[5rem] w-[5rem]': variant === 'medium'
        },
        className
      )}
      {...props}
    >
      <svg viewBox="25 25 50 50" className="animate-spin">
        <circle
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth={variant === 'small' ? 6 : 2}
          strokeMiterlimit="10"
          className="stroke-white-light dark:stroke-black-light"
        />
      </svg>
    </span>
  )
}

export default Spinner
