import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { usePrevious } from 'react-use'
import { useGesture } from '@use-gesture/react'
import classNames from 'classnames'
import gsap from 'gsap'
import Lenis from 'lenis'
import { find } from 'lodash'
import { useRouter } from 'next/router'

import ButtonIcon from '@/components/base/ButtonIcon'
import { ContainerFluid } from '@/components/base/Container'
import Grid from '@/components/base/Grid'
import LangSelector from '@/components/base/LangSelector'
import Link from '@/components/base/LinkRouter'
import SVGIcon from '@/components/base/SVGIcon'
import Typography from '@/components/base/Typography'
import { isTouch } from '@/utils/device'
import { stripSlash } from '@/utils/path'

import { useStore } from '@/contexts/store'

type MenuProps = {
  menuOpen?: boolean
  onClose?: () => void
}

const Menu = ({ menuOpen, onClose }: MenuProps) => {
  const { menu, strings } = useStore()
  const { asPath, locale } = useRouter()
  const [subMenuOpen, setSubMenuOpen] = useState(-1)
  const [needsOverflowBackground, setNeedsOverflowBackground] = useState(false)
  const prevSubMenuOpen = usePrevious(subMenuOpen)
  const wrapper = useRef<HTMLDivElement>(null!)
  const content = useRef<HTMLDivElement>(null!)
  const submenu = useRef<HTMLDivElement>(null!)
  const panel = useRef<HTMLDivElement>(null!)

  /*
   * Handle menu overflow background (IOS scroll bounce fix)
   */
  const handlePanelTransitionEnd = useCallback(() => {
    const panelBBox = panel.current.getBoundingClientRect()
    const headerOffset = window.innerWidth < 768 ? 96 : 143
    const panelHeight = panelBBox.height + headerOffset
    setNeedsOverflowBackground(panelHeight > window.innerHeight)
  }, [])

  /*
   * Handle menu close on outside click
   */
  const handleClickOutside = (event: MouseEvent) => {
    if (
      event.target instanceof HTMLDivElement &&
      event.target.classList.contains('lenis')
    ) {
      onClose && onClose()
    }
  }

  /**
   * Handle menu inner scroll
   */
  useEffect(() => {
    const lenis = new Lenis({
      smoothWheel: true,
      wrapper: wrapper.current,
      content: content.current
    })

    const tick = (time: number) => {
      lenis.raf(time * 1000)
    }

    gsap.ticker.add(tick)

    return () => {
      gsap.ticker.remove(tick)
      lenis.destroy()
    }
  }, [])

  /**
   * Handle submenu swipe back
   */
  useGesture(
    {
      onDrag: ({ swipe }) => {
        if (!isTouch) return
        const swipeX = swipe[0]
        if (swipeX <= 0 || subMenuOpen === -1) return
        setSubMenuOpen(-1)
      }
    },
    {
      target: submenu,
      drag: {
        axis: 'x'
      }
    }
  )

  /**
   * Clear submenu on menu close
   */
  useEffect(() => {
    if (menuOpen) return
    gsap.delayedCall(0.5, () => {
      setSubMenuOpen(-1)
    })
  }, [menuOpen])

  return (
    <div
      ref={wrapper}
      className={classNames(
        'lenis dark fixed left-0 right-0 top-[9.6rem] z-menu  overflow-auto md:top-[14.3rem]',
        { 'h-0 transition-[height] delay-500 duration-0': !menuOpen },
        { 'h-[calc(100%_-_9.6rem)] md:h-[calc(100%_-_14.3rem)]': menuOpen },
        {
          'bg-background-primary-light dark:bg-background-primary-dark':
            menuOpen && needsOverflowBackground
        }
      )}
      onClick={handleClickOutside}
    >
      <div ref={content} className="overflow-hidden">
        <div
          ref={panel}
          onTransitionEnd={handlePanelTransitionEnd}
          className={classNames(
            'grid max-h-full bg-background-primary-light dark:bg-background-primary-dark',
            'transition-all duration-500 ease-in-out-cubic',
            { 'grid-rows-[0fr]': !menuOpen },
            { 'grid-rows-[1fr]': menuOpen }
          )}
        >
          <ContainerFluid className="overflow-hidden">
            <Grid className="pb-spacing-lg pt-[3rem]">
              <div className="col-span-2 md:col-span-6 lg:col-span-8">
                <div className="relative grid grid-cols-2 gap-x-gutter pb-spacing-2xl md:grid-cols-6 lg:grid-cols-8 lg:pb-0">
                  <div
                    className={classNames(
                      'col-span-2 -my-[1rem] flex flex-col space-y-spacing-lg py-[1rem] md:col-span-6 lg:col-span-4 lg:my-0 lg:py-0',
                      'transition-transform duration-500 ease-in-out-cubic',
                      {
                        '-translate-x-1/2': subMenuOpen !== -1
                      },
                      'lg:translate-x-0 lg:transition-none'
                    )}
                  >
                    <nav>
                      <ul className="flex flex-col space-y-spacing-sm">
                        {menu?.headerMenu01?.menu.map(
                          ({ id, title, url, children }, index) => {
                            const active =
                              find(
                                children || [],
                                ({ url }) =>
                                  stripSlash(url) ===
                                  stripSlash(`/${locale}${asPath}`)
                              ) !== undefined

                            return (
                              <li key={`menu-1-${index}`} className="flex">
                                {children.length > 0 ? (
                                  <button
                                    className={classNames(
                                      'relative -m-spacing-xs flex grow p-spacing-xs',
                                      'transition-opacity duration-300 ease-out-cubic pointer:hover:opacity-40'
                                    )}
                                    onClick={() =>
                                      setSubMenuOpen(
                                        id !== subMenuOpen ? id : -1
                                      )
                                    }
                                  >
                                    <Typography
                                      variant="h5"
                                      className={classNames({
                                        'text-text-primary-light dark:text-text-primary-dark':
                                          id !== subMenuOpen && !active,
                                        'text-text-primary-selected-light':
                                          id === subMenuOpen || active
                                      })}
                                    >
                                      {title}
                                    </Typography>
                                    <span
                                      className={classNames(
                                        'absolute right-0 top-1/2 h-[1.6rem] w-[1.6rem] shrink-0 -translate-x-full -translate-y-1/2',
                                        'before:absolute before:left-1/2 before:top-1/2 before:h-[1.2rem] before:w-[.25rem] before:-translate-x-1/2 before:-translate-y-1/2 before:rounded-full',
                                        'before:transition-all before:duration-300 before:ease-out-cubic',
                                        'after:absolute after:left-1/2 after:top-1/2 after:h-[.25rem] after:w-[1.2rem] after:-translate-x-1/2 after:-translate-y-1/2 after:rounded-full',
                                        'after:transition-all after:duration-300 after:ease-out-cubic',
                                        {
                                          'before:bg-surface-primay-dark':
                                            id !== subMenuOpen && !active,
                                          'after:bg-surface-primay-dark':
                                            id !== subMenuOpen && !active
                                        },
                                        {
                                          'before:bg-text-primary-selected-light':
                                            id === subMenuOpen || active,
                                          'after:bg-text-primary-selected-light':
                                            id === subMenuOpen || active
                                        },
                                        {
                                          'before:rotate-90':
                                            id === subMenuOpen,
                                          'after:rotate-180': id === subMenuOpen
                                        }
                                      )}
                                    />
                                  </button>
                                ) : (
                                  <Link
                                    href={url}
                                    className="-m-spacing-xs flex p-spacing-xs"
                                    tabIndex={menuOpen ? 0 : -1}
                                  >
                                    <Typography
                                      variant="h5"
                                      className="text-text-primary-light dark:text-text-primary-dark"
                                    >
                                      {title}
                                    </Typography>
                                  </Link>
                                )}
                              </li>
                            )
                          }
                        )}
                      </ul>
                    </nav>
                    <nav>
                      <ul className="flex flex-col space-y-spacing-sm md:space-y-spacing-sm">
                        {menu?.headerMenu02?.menu.map(
                          ({ title, url }, index) => (
                            <li key={`menu-2-${index}`} className="flex">
                              <Link
                                href={url}
                                className="-m-spacing-xs flex p-spacing-xs"
                                tabIndex={menuOpen ? 0 : -1}
                              >
                                <Typography
                                  variant="h6"
                                  className="text-text-primary-light dark:text-text-primary-dark"
                                >
                                  {title}
                                </Typography>
                              </Link>
                            </li>
                          )
                        )}
                      </ul>
                    </nav>
                  </div>
                  <div
                    ref={submenu}
                    className={classNames(
                      'touch-pan-y bg-background-primary-light dark:bg-background-primary-dark',
                      'absolute -left-[2rem] -right-[2rem] -top-[1rem] bottom-0 md:-left-[4rem] md:-right-[4rem] lg:top-0',
                      'transition-transform duration-500 ease-in-out-cubic',
                      {
                        'translate-x-full': subMenuOpen === -1,
                        'translate-x-0': subMenuOpen !== -1
                      },
                      'lg:relative lg:left-auto lg:right-auto lg:col-span-4 lg:translate-x-0 lg:transition-none'
                    )}
                  >
                    <button
                      className="absolute left-[0.4rem] top-0 z-10 flex h-[4.4rem] w-[4.4rem] -translate-y-[.6rem] items-center justify-center md:left-spacing-sm md:-translate-y-[.0rem] lg:hidden"
                      aria-label="Back"
                    >
                      <SVGIcon
                        symbol="back-icon"
                        className="h-[1.2rem] w-[.8rem] fill-text-primary-light dark:fill-text-primary-dark"
                        onClick={() => setSubMenuOpen(-1)}
                      />
                    </button>
                    {menu?.headerMenu01?.menu.map(({ id, children }) => (
                      <div
                        key={`submenu-1-${id}`}
                        className={classNames(
                          'absolute -top-spacing-xxs left-0 grid py-[1rem] lg:py-0',
                          'transition-all',
                          {
                            'grid-rows-[0fr]': subMenuOpen !== id,
                            'grid-rows-[1fr]': subMenuOpen === id
                          },
                          {
                            'delay-500': subMenuOpen === -1
                          },
                          'lg:delay-0 lg:duration-500',
                          {
                            'lg:ease-out-cubic': subMenuOpen !== id,
                            'lg:ease-in-out-cubic': subMenuOpen === id,
                            'lg:delay-200':
                              subMenuOpen === id && prevSubMenuOpen !== -1
                          }
                        )}
                      >
                        <nav className="overflow-hidden px-[2rem] md:px-[4rem] lg:px-0">
                          <ul className="xs flex flex-col space-y-spacing-sm px-spacing-md py-spacing-xxs lg:px-0">
                            {children.map(({ url, title }, index) => (
                              <li
                                key={`submenu-1-${id}-${index}`}
                                className="flex"
                              >
                                <Link
                                  href={url}
                                  className="-m-spacing-xs flex p-spacing-xs"
                                  tabIndex={menuOpen ? 0 : -1}
                                >
                                  <Typography
                                    variant="h5"
                                    className="text-text-primary-light lg:text-text-primary-selected-light dark:text-text-primary-dark dark:lg:text-text-primary-selected-light"
                                  >
                                    {title}
                                  </Typography>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-span-2 md:col-span-6 lg:col-span-4">
                <div className="grid grid-cols-2 gap-x-gutter gap-y-spacing-lg md:grid-cols-4">
                  {strings?.menuBtn?.link.url ? (
                    <div className="col-span-2 md:col-span-4">
                      <ButtonIcon
                        href={strings?.menuBtn?.link.url}
                        icon={strings?.menuBtn?.icon}
                      >
                        {strings?.menuBtn?.link.title}
                      </ButtonIcon>
                    </div>
                  ) : null}

                  <div className="col-span-1 flex flex-col space-y-spacing-xs md:col-span-2">
                    {menu?.headerMenu04?.menu.map(({ title, url }, index) => (
                      <div key={`menu-4-${index}`} className="flex">
                        <Link
                          href={url}
                          className="-m-spacing-xxs flex p-spacing-xxs"
                          tabIndex={menuOpen ? 0 : -1}
                        >
                          <Typography
                            variant="small"
                            className="text-text-secondary-light dark:text-text-secondary-dark"
                          >
                            {title}
                          </Typography>
                        </Link>
                      </div>
                    ))}
                    <div className="!mt-spacing-sm flex">
                      <LangSelector />
                      {/* <Typography
                    variant="small"
                    className="capitalize text-text-secondary-light dark:text-text-secondary-dark"
                  >
                    {useLocaleStore.getState().currentLocale === 'it'
                      ? TRANSLATIONS[1]
                      : TRANSLATIONS[0]}
                  </Typography> */}
                    </div>
                  </div>
                  <div className="col-span-1 flex flex-col space-y-spacing-xs md:col-span-2">
                    {menu?.headerMenu03?.menu.map(({ title, url }, index) => (
                      <div key={`menu-3-${index}`} className="flex">
                        <Link
                          href={url}
                          className="-m-spacing-xxs flex p-spacing-xxs"
                          tabIndex={menuOpen ? 0 : -1}
                        >
                          <Typography
                            variant="small"
                            className="text-text-secondary-light dark:text-text-secondary-dark"
                          >
                            {title}
                          </Typography>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Grid>
          </ContainerFluid>
        </div>
      </div>
    </div>
  )
}

export default Menu
