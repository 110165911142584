import { EventEmitter } from 'events'

export const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL
export const FRONT_BASE_URL = process.env.NEXT_PUBLIC_FRONT_BASE_URL
export const LANGUAGES = JSON.parse(process.env.NEXT_PUBLIC_LANGUAGES!)

export const API = {
  ROUTES: '/wp-json/gusto/routes',
  STRINGS: '/wp-json/gusto/options',
  POSTS: '/wp-json/wp/v2/posts',
  FILTERS:
    '/wp-json/wp/v2/tipologia?_fields[]=description&_fields[]=name&_fields[]=slug&_fields[]=count&_fields[]=id&_fields[]=acf',
  MENU_LOCATIONS: '/wp-json/gusto/nav/menu-locations',
  CF7_FEEDBACK: '/wp-json/contact-form-7/v1/contact-forms'
}

export const MediaQuery = {
  SMALL: '(max-width: 1023px)',
  LARGE: '(min-width: 1024px)',
  TOUCH: '(hover: none) and (pointer: coarse)',
  POINTER: '(hover: hover) and (pointer: fine)'
}

export const EMITTER = new EventEmitter()
export const EVENTS = {
  DATALAYER: {
    PRODUCT_VIEW: 'product_view'
  }
}
