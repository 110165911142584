import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import gsap from 'gsap'

import { Lenis, useLenis } from '@/contexts/lenis'

const Scrollbar = () => {
  const { lenis } = useLenis()
  const thumb = useRef<HTMLDivElement>(null!)
  const thumbPointerOffset = useRef(0)
  const thumbHeight = useRef(0)
  const trackHeight = useRef(0)

  const [isDragged, setIsDragged] = useState(false)

  useEffect(() => {
    if (!lenis) return

    const update = (lenis: Lenis) => {
      if (isDragged) return
      gsap.set(thumb.current, {
        y: lenis.progress * (trackHeight.current - thumbHeight.current)
      })
    }

    lenis.on('scroll', update)

    return () => {
      lenis.off('scroll', update)
    }
  }, [lenis, isDragged])

  useEffect(() => {
    if (!lenis || !isDragged) return

    const handleMouseDown = (event: MouseEvent) => {
      event.preventDefault()

      const thumbPosition = gsap.getProperty(thumb.current, 'y') as number
      thumbPointerOffset.current = event.clientY - thumbPosition
    }

    const handleMouseMove = (event: MouseEvent) => {
      event.preventDefault()

      const thumbMaxPosition = trackHeight.current - thumbHeight.current
      const thumbPointerPosition = event.clientY - thumbPointerOffset.current
      const thumbPosition = gsap.utils.clamp(
        0,
        thumbMaxPosition,
        thumbPointerPosition
      )

      const scrollPosition = gsap.utils.mapRange(
        0,
        thumbMaxPosition,
        0,
        lenis.limit,
        thumbPosition
      )

      gsap.set(thumb.current, { y: thumbPosition })

      lenis.scrollTo(scrollPosition, { immediate: true })
    }

    const handleMouseUp = () => {
      setIsDragged(false)
    }

    window.addEventListener('mousedown', handleMouseDown, {
      passive: false
    })
    window.addEventListener('mousemove', handleMouseMove, {
      passive: false
    })
    window.addEventListener('mouseup', handleMouseUp)

    return () => {
      window.removeEventListener('mousedown', handleMouseDown)
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [lenis, isDragged])

  useEffect(() => {
    const resize = () => {
      if (!lenis) return
      const height = window.innerHeight
      const scrollHeight = lenis.dimensions.content?.scrollHeight || 0
      trackHeight.current = height
      thumbHeight.current = height * (height / scrollHeight)
      gsap.set(thumb.current, { height: thumbHeight.current })
    }

    window.addEventListener('resize', resize)

    resize()

    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [lenis])

  useEffect(() => {
    document.body.style.userSelect = isDragged ? 'none' : 'auto'
  }, [isDragged])

  return (
    <div className="group fixed bottom-0 right-0 top-0 z-scrollbar w-[1rem] touch:hidden">
      <div
        className={classNames(
          'relative h-full w-full bg-transparent',
          'transition-transform duration-300 ease-out-cubic',
          'pointer:group-hover:translate-x-[.2rem]',
          {
            'translate-x-[.6rem]': !isDragged,
            'translate-x-[.2rem]': isDragged
          }
        )}
      >
        <div
          ref={thumb}
          className="absolute left-0 top-0 w-full bg-text-overline-light"
          onPointerDown={() => setIsDragged(true)}
        />
      </div>
    </div>
  )
}

export default Scrollbar
