import { EMITTER, EVENTS } from '@/constants'

const pushToDataLayer = (data: any) => {
  ;((global as any).dataLayer || []).push({
    event: 'view_item',
    ...data
  })
}

const tracking = () => {
  const { PRODUCT_VIEW } = EVENTS.DATALAYER

  EMITTER.on(PRODUCT_VIEW, (payload: any) => {
    pushToDataLayer({
      eventCategory: 'Ecommerce',
      eventAction: `View Item`,
      ...payload
    })
  })
}

export default tracking
