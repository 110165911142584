import { useMemo, useRef, useState } from 'react'
import { Transition } from 'react-transition-group'
import { useIsomorphicLayoutEffect } from 'react-use'
import { useGSAP } from '@gsap/react'
import classNames from 'classnames'
import gsap from 'gsap'

import Button from '@/components/base/Button'
import Container from '@/components/base/Container'
import SVGIcon from '@/components/base/SVGIcon'
import Typography from '@/components/base/Typography'
import { useAgeGateStore } from '@/store/agegate'

import { useStore } from '@/contexts/store'
import {
  MASK_TRANSITION_LEAVE_DURATION,
  MASK_TRANSITION_STAGGER_AMOUNT
} from '@/contexts/transition'

const AgeGate = () => {
  const root = useRef(null)
  const { strings } = useStore()
  const [forbidden, setForbidden] = useState(false)
  const [needsAgeGate, setNeedsAgeGate] = useState(false)
  const [needsAgeGateModal, setNeedsAgeGateModal] = useState(true)
  const setState = useAgeGateStore((state) => state.setState)

  const duration = useMemo(() => MASK_TRANSITION_LEAVE_DURATION, [])
  const stagger = useMemo(() => MASK_TRANSITION_STAGGER_AMOUNT, [])
  const timeout = useMemo(() => duration + stagger, [duration, stagger])

  const { contextSafe } = useGSAP({ scope: root })

  const handleLeaveTransition = contextSafe(() =>
    gsap
      .timeline()
      .add(
        gsap
          .timeline()
          .to('[data-agegate-wrapper]', {
            yPercent: -100,
            duration,
            ease: 'power3.inOut'
          })
          .to(
            '[data-agegate-content]',
            {
              yPercent: 70,
              duration,
              ease: 'power3.inOut'
            },
            '<'
          )
      )
      .add(
        gsap.to('[data-agegate-primary]', {
          yPercent: -100,
          duration,
          ease: 'power3.inOut'
        }),
        `<+${stagger}`
      )
  )

  useIsomorphicLayoutEffect(() => {
    const unsubscribe = useAgeGateStore.subscribe(
      (state) => state.needsAgeGate,
      (needsPersistAgeGate, prevNeedsPersistAgeGate) => {
        if (needsPersistAgeGate) {
          setNeedsAgeGate(true)
        } else if (prevNeedsPersistAgeGate) {
          setNeedsAgeGateModal(false)
        }
      },
      {
        fireImmediately: true
      }
    )

    return () => {
      unsubscribe()
    }
  }, [])

  return needsAgeGate ? (
    <Transition
      in={needsAgeGateModal}
      nodeRef={root}
      timeout={timeout * 1000}
      onExit={handleLeaveTransition}
      enter={false}
      unmountOnExit
    >
      <div
        ref={root}
        className="dark fixed left-0 top-0 z-agegate h-full w-full touch-none"
      >
        <div
          className="absolute left-0 top-0 h-full w-full bg-text-overline-light"
          data-agegate-primary
        />
        <div
          className="absolute left-0 top-0 h-full w-full overflow-hidden"
          data-agegate-wrapper
        >
          <div
            className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-black-light dark:bg-black-dark"
            data-agegate-content
          >
            <Container>
              <div className="grid items-center justify-center gap-y-spacing-lg">
                <SVGIcon
                  symbol="large-logo-icon"
                  className="mx-auto h-[9.2rem] w-[15.4rem] md:h-[13.2rem] md:w-[22rem]"
                />
                <Typography
                  variant="h5"
                  component="h1"
                  className="text-center text-text-primary-light dark:text-text-primary-dark"
                >
                  {strings?.ageGate?.text}
                </Typography>
                <div className="flex items-center justify-center space-x-spacing-xxs">
                  <Button
                    variant="contained"
                    onClick={() => setState({ needsAgeGate: false })}
                  >
                    {strings?.ageGate?.answerYes}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => setForbidden(true)}
                    className={classNames(
                      'transition-colors duration-300 ease-out-cubic',
                      {
                        '!border-error !bg-error !text-text-primary-dark':
                          forbidden
                      }
                    )}
                  >
                    {strings?.ageGate?.answerNo}
                  </Button>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </Transition>
  ) : null
}

export default AgeGate
