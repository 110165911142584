import { ButtonHTMLAttributes } from 'react'
import classNames from 'classnames'

import Typography from '@/components/base/Typography'

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  Partial<{
    variant: 'contained' | 'outlined' | 'rounded'
    selected: boolean
  }>

const Button = ({
  variant = 'contained',
  children,
  className,
  selected = false,
  ...props
}: ButtonProps) => {
  const variantClasses = classNames(
    'flex p-[1.2rem]',
    {
      'border border-surface-primay-light bg-surface-primay-light text-text-button-primary-light dark:border-surface-primay-dark dark:bg-surface-primay-dark dark:text-text-button-primary-dark':
        variant === 'contained'
    },
    {
      'border border-border-primary-light text-text-button-secondary-light dark:border-border-primary-dark dark:text-text-button-secondary-dark':
        variant === 'outlined'
    },
    {
      'transition-colors duration-300 ease-in-out-cubic rounded-radius-minimal border border-transparent text-text-button-secondary-light dark:text-text-button-secondary-dark':
        variant === 'rounded',
      'bg-transparent': variant === 'rounded' && !selected,
      'bg-surface-button-filter-selected-light dark:bg-surface-button-filter-selected-dark':
        variant === 'rounded' && selected
    }
  )

  return (
    <button
      {...props}
      className={classNames('group', variantClasses, className)}
    >
      <span
        data-label={children as string}
        className="relative block overflow-hidden p-[.4rem] after:absolute after:left-0 after:top-0 after:-mb-[0.35em] after:-mt-[0.3em] after:w-full after:translate-y-full after:p-[.4rem] after:font-roboto after:text-small after:transition-transform after:duration-300 after:ease-out-cubic after:content-[attr(data-label)] pointer:group-hover:after:translate-y-0"
      >
        <Typography
          variant="small"
          className="block transition-transform duration-300 ease-out-cubic pointer:group-hover:-translate-y-full"
        >
          {children}
        </Typography>
      </span>
    </button>
  )
}

export default Button
