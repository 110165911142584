import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useIsomorphicLayoutEffect } from 'react-use'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { default as LenisImpl } from 'lenis'
import { shallow } from 'zustand/shallow'

import Scrollbar from '@/components/base/Scrollbar'
import { useAgeGateStore } from '@/store/agegate'
import { useLayoutStore } from '@/store/layout'
import { isTouch } from '@/utils/device'

import { State, useLenisStore } from './useLenisStore'
import { TRANSITION_LEAVE_DURATION } from '@/contexts/transition'

export type Lenis = LenisImpl & Pick<State, 'dimensions'>

export const LenisContext = createContext<{ lenis?: Lenis } | undefined>(
  undefined
)

const LenisProvider = ({ children }: PropsWithChildren) => {
  const wrapper = useRef<HTMLDivElement>(null!)
  const content = useRef<HTMLDivElement>(null!)
  const [lenis, setLenis] = useState<Lenis>()
  const setState = useLenisStore((state) => state.setState)

  const update = useCallback(
    ({ scroll, limit, progress, dimensions }: Lenis) => {
      ScrollTrigger.update()
      setState({ scroll, limit, progress, dimensions })
    },
    [setState]
  )

  useIsomorphicLayoutEffect(() => {
    const lenis = new LenisImpl({
      smoothWheel: true,
      eventsTarget: document.body,
      ...(!isTouch && {
        wrapper: wrapper.current,
        content: content.current
      })
    })

    if (!isTouch) {
      wrapper.current.style.overflow = 'auto'
      ScrollTrigger.defaults({ scroller: wrapper.current })
      ScrollTrigger.scrollerProxy(wrapper.current, {
        scrollTop() {
          return lenis.scroll
        },
        getBoundingClientRect() {
          return {
            top: 0,
            left: 0,
            width: window.innerWidth,
            height: window.innerHeight
          }
        }
      })
    }

    setLenis(lenis as Lenis)

    return () => {
      lenis.destroy()
      setLenis(undefined)
    }
  }, [])

  useIsomorphicLayoutEffect(() => {
    if (!lenis) return

    const tick = (time: number) => {
      lenis.raf(time * 1000)
    }

    gsap.ticker.add(tick)
    // gsap.ticker.lagSmoothing(0)

    lenis.on('scroll', update)

    update(lenis as Lenis)

    return () => {
      gsap.ticker.remove(tick)
      lenis.off('scroll', update)
    }
  }, [lenis, update])

  useIsomorphicLayoutEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, [])

  useEffect(() => {
    if (!lenis) return

    let delayedCall: gsap.core.Tween

    const updateState = ({ needsAgeGate, needsVideoIntro }: any) => {
      if (delayedCall) {
        delayedCall.kill()
      }

      if (needsAgeGate || needsVideoIntro) {
        lenis.stop()
      } else if (lenis.isStopped) {
        delayedCall = gsap.delayedCall(TRANSITION_LEAVE_DURATION, () => {
          lenis.start()
        })
      }
    }

    const unsubscribeAgeGate = useAgeGateStore.subscribe(
      (state) => state.needsAgeGate,
      (needsAgeGate) => {
        updateState({
          needsAgeGate,
          needsVideoIntro:
            useLayoutStore.getState().needsVideoIntro &&
            !useLayoutStore.getState().videoIntroSkipped
        })
      },
      {
        fireImmediately: true
      }
    )

    const unsubscribeVideoIntro = useLayoutStore.subscribe(
      (state) => [state.needsVideoIntro, state.videoIntroSkipped],
      ([needsVideoIntro, videoIntroSkipped]) => {
        updateState({
          needsAgeGate: useAgeGateStore.getState().needsAgeGate,
          needsVideoIntro: needsVideoIntro && !videoIntroSkipped
        })
      },
      {
        fireImmediately: true,
        equalityFn: shallow
      }
    )

    return () => {
      unsubscribeAgeGate()
      unsubscribeVideoIntro()
    }
  }, [lenis])

  useEffect(() => {
    if (!lenis) return
    lenis.scrollTo(0, { immediate: true })
  }, [lenis])

  const contextValue = useMemo(() => ({ lenis }), [lenis])

  return (
    <LenisContext.Provider value={contextValue}>
      <div ref={wrapper} className="relative h-full w-full">
        <div ref={content} className="static h-full w-full">
          {children}
        </div>
      </div>
      <Scrollbar />
    </LenisContext.Provider>
  )
}

export default LenisProvider
