import SVGIcon from '@/components/base/SVGIcon'

const Logo = () => {
  return (
    <span className="flex h-[5.6rem] w-[5.6rem] items-center justify-center md:h-[6.3rem] md:w-[6.3rem]">
      <SVGIcon
        symbol="logo-icon"
        className="h-[4rem] w-[4rem] md:h-[4.7rem] md:w-[4.7rem]"
      />
    </span>
  )
}

export default Logo
