import { PropsWithChildren } from 'react'
import classNames from 'classnames'

import Link, { LinkProps } from '@/components/base/Link'
import Typography from '@/components/base/Typography'

type ButtonIconProps = PropsWithChildren<LinkProps> & { icon: string }

const ButtonIcon = ({ children, icon, ...props }: ButtonIconProps) => {
  return (
    <Link
      className="group flex w-full items-center justify-between space-x-spacing-xxs bg-surface-primay-dark p-spacing-xs"
      {...props}
    >
      <span
        data-label={children as string}
        className="relative block overflow-hidden p-[.4rem] after:absolute after:left-0 after:top-0 after:-mb-[0.35em] after:-mt-[0.3em] after:w-full after:translate-y-full after:p-[.4rem] after:font-roboto after:text-small after:transition-transform after:duration-300 after:ease-out-cubic after:content-[attr(data-label)] pointer:group-hover:after:translate-y-0"
      >
        <Typography
          variant="small"
          className={classNames(
            'block text-text-button-primary-light dark:text-text-button-primary-dark',
            'transition-transform duration-300 ease-out-cubic pointer:group-hover:-translate-y-full'
          )}
        >
          {children}
        </Typography>
      </span>
      <span
        className="flex [&>*]:h-[1.3rem] [&>*]:w-[1.6rem] [&>*]:fill-text-button-primary-light [&>*]:dark:fill-text-button-primary-dark"
        dangerouslySetInnerHTML={{ __html: icon }}
      />
    </Link>
  )
}

export default ButtonIcon
