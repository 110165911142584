import { AxiosError, AxiosResponse } from 'axios'

import { axiosServer as axios } from '@/base/axios'

export const getPromiseFromFetch = <T = any>({
  params = {},
  config = {},
  method = 'get',
  url = ''
}) =>
  new Promise<AxiosResponse<T>>((resolve) =>
    (axios as any)
      [method](url, params, config) // eslint-disable-line
      .then(resolve)
      .catch((error: AxiosError) => resolve(error.response as AxiosResponse))
  )
