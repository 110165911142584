/* eslint-disable @next/next/no-html-link-for-pages */
import { useEffect, useState } from 'react'
import { useKey } from 'react-use'
import classNames from 'classnames'
import gsap from 'gsap'
import NextLink from 'next/link'
import { useRouter } from 'next/router'

import { ContainerFluid } from '@/components/base/Container'
import Link from '@/components/base/LinkRouter'
import Logo from '@/components/base/Logo'
import SVGIcon from '@/components/base/SVGIcon'
import Typography from '@/components/base/Typography'
import Menu from '@/components/layout/Menu'

import { useLenisStore } from '@/contexts/lenis'
import { useStore } from '@/contexts/store'
import { TRANSITION_ENTER_DURATION } from '@/contexts/transition'

type HeaderProps = {
  hasHero?: boolean
}

const Header = ({ hasHero }: HeaderProps) => {
  const { menu } = useStore()
  const [menuOpen, setMenuOpen] = useState(false)

  const [needsBackground, setNeedsBackground] = useState(false)
  const [theme, setTheme] = useState(hasHero ? 'light' : 'dark')

  const router = useRouter()

  /**
   * Handle header menu close on escape key
   */
  useKey('Escape', () => setMenuOpen(false))

  /**
   * Handle header scroll state
   */
  useEffect(() => {
    const unsubscribe = useLenisStore.subscribe(
      (state) => state.scroll,
      (scroll) => {
        if (hasHero) {
          const offset = window.innerWidth < 768 ? 96 : 143
          if (scroll > window.innerHeight - offset / 2) {
            if (!needsBackground) {
              setNeedsBackground(true)
            }
          } else if (needsBackground) {
            setNeedsBackground(false)
          }
        } else {
          if (scroll > 0) {
            if (!needsBackground) {
              setNeedsBackground(true)
              setTheme('light')
            }
          } else if (needsBackground) {
            setNeedsBackground(false)
            setTheme('dark')
          }
        }
      },
      {
        fireImmediately: true
      }
    )

    return () => {
      unsubscribe()
    }
  }, [hasHero, needsBackground])

  /**
   * Handle header transition state
   */
  useEffect(() => {
    gsap.delayedCall(TRANSITION_ENTER_DURATION, () => {
      setTheme(hasHero ? 'light' : 'dark')
      setMenuOpen(false)
    })
  }, [router.asPath, hasHero])

  return (
    <header className="static">
      <div className="fixed left-0 top-0 z-header w-full overflow-hidden py-[2rem] md:py-spacing-lg">
        <div
          className={classNames(
            'absolute left-0 top-0 h-full w-full',
            'transition-transform duration-300 ease-out-cubic will-change-transform',
            {
              '-translate-y-0': needsBackground,
              '-translate-y-full': !needsBackground && !menuOpen
            }
          )}
        >
          <div
            className={classNames(
              'absolute left-0 top-0 h-full w-full',
              'transition-colors ease-out-cubic',
              {
                'bg-background-primary-light dark:bg-background-primary-dark':
                  theme === 'dark',
                'bg-background-primary-dark dark:bg-background-primary-light':
                  theme === 'light',
                '!dark:bg-background-primary-light !bg-background-primary-dark':
                  menuOpen
              },
              {
                'delay-0 duration-300': needsBackground,
                'delay-300 duration-0': !needsBackground && !menuOpen
              }
            )}
          />
        </div>
        <ContainerFluid className="relative flex items-center justify-between">
          <NextLink href="/" scroll={false} aria-label="Home">
            <Logo />
          </NextLink>
          <div
            className={classNames('flex items-center space-x-spacing-lg', {
              dark: theme === 'dark'
            })}
          >
            <nav className="hidden md:flex">
              <ul className="flex items-center space-x-spacing-md">
                {menu?.mainMenu?.menu.map(({ title, url }, index) => (
                  <li
                    key={`nav-${index}`}
                    className="-m-spacing-xxs overflow-hidden p-spacing-xxs"
                  >
                    <span
                      className={classNames(
                        '-m-spacing-xxs flex p-spacing-xxs',
                        'transition-transform duration-500 ease-out-cubic',
                        {
                          'translate-y-full': menuOpen
                        }
                      )}
                    >
                      <Link
                        href={url}
                        className="-m-spacing-xxs flex p-spacing-xxs"
                        tabIndex={menuOpen ? -1 : 0}
                      >
                        <Typography
                          variant="menu"
                          className="text-text-primary-dark dark:text-text-primary-light"
                        >
                          {title}
                        </Typography>
                      </Link>
                    </span>
                  </li>
                ))}
              </ul>
            </nav>

            <div className="relative">
              <button
                className="group -m-spacing-xs flex space-x-spacing-xxs p-spacing-xs"
                onClick={() => setMenuOpen((menuOpen) => !menuOpen)}
                aria-label="Open Menu"
                tabIndex={menuOpen ? -1 : 0}
              >
                <span
                  className={classNames(
                    'block h-[.6rem] w-[.6rem] rounded-full bg-text-primary-dark dark:bg-text-primary-light',
                    'transition-all duration-300 ease-out-cubic',
                    'pointer:group-hover:-translate-x-[.2rem]',
                    {
                      'translate-x-[.7rem] scale-0': menuOpen
                    },
                    {
                      '!bg-text-primary-dark': menuOpen
                    }
                  )}
                />
                <span
                  className={classNames(
                    'block h-[.6rem] w-[.6rem] rounded-full bg-text-primary-dark dark:bg-text-primary-light',
                    'transition-all duration-300 ease-out-cubic',
                    'pointer:group-hover:translate-x-[.2rem]',
                    {
                      '-translate-x-[.7rem] scale-0': menuOpen
                    },
                    {
                      '!bg-text-primary-dark': menuOpen
                    }
                  )}
                />
              </button>
              <button
                className={classNames(
                  'absolute left-1/2 top-1/2 -translate-x-[1.9rem] -translate-y-1/2 p-spacing-xs',
                  'transition-transform duration-300 ease-out-cubic',
                  {
                    'rotate-45 scale-0': !menuOpen
                  }
                )}
                onClick={() => setMenuOpen((menuOpen) => !menuOpen)}
                aria-label="Close Menu"
                tabIndex={menuOpen ? 0 : -1}
              >
                <SVGIcon
                  symbol="close-icon"
                  className={classNames(
                    'h-[1rem] w-[1rem] fill-text-primary-dark dark:fill-text-primary-light',
                    {
                      '!fill-text-primary-dark': menuOpen
                    }
                  )}
                />
              </button>
            </div>
          </div>
        </ContainerFluid>
      </div>

      <Menu menuOpen={menuOpen} onClose={() => setMenuOpen(false)} />
    </header>
  )
}

export default Header
