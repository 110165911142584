import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

type State = {
  currentRoute?: string
  previousRoute?: string
}

type Actions = {
  // eslint-disable-next-line no-unused-vars
  setCurrentRoute: (route?: string) => void
}

const initialState: State = {
  currentRoute: undefined,
  previousRoute: undefined
}

const useRouterStore = create(
  subscribeWithSelector(
    immer<State & Actions>((set) => ({
      ...initialState,
      setCurrentRoute: (route) =>
        set((state) => ({
          previousRoute: state.currentRoute,
          currentRoute: route
        }))
    }))
  )
)

export { useRouterStore }
