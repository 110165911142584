import { useRef, useState } from 'react'
import { useClickAway, useMedia } from 'react-use'
import classNames from 'classnames'

import SVGIcon from '@/components/base/SVGIcon'
import Typography from '@/components/base/Typography'
import { useLocaleStore } from '@/store/locale'
import { LANGUAGES, MediaQuery } from '@/constants'

const LangSelector = () => {
  const root = useRef(null)
  const [open, setOpen] = useState(false)
  const isTouch = useMedia(MediaQuery.TOUCH, false)
  const [currentLocale, currentTranslations] = useLocaleStore((state) => [
    state.currentLocale,
    state.currentTranslations
  ])

  useClickAway(root, () => {
    if (isTouch) return
    setOpen(false)
  })

  return (
    <div
      ref={root}
      className="border-2 border-text-secondary-light dark:border-text-secondary-dark"
    >
      <button
        className="flex items-center space-x-[1rem] p-spacing-xs"
        onClick={() => setOpen((open) => !open)}
      >
        <Typography
          variant="small"
          className="capitalize text-text-secondary-light dark:text-text-secondary-dark"
        >
          Lang
        </Typography>
        <span
          className={classNames(
            'flex h-[.8rem] w-[.8rem] items-center justify-center',
            'origin-center transition-all duration-300 ease-in-out-cubic',
            { 'rotate-180': open }
          )}
        >
          <SVGIcon
            symbol="chevron-icon-small"
            className="h-[.4rem] w-[.6rem] fill-text-secondary-light dark:fill-text-secondary-dark"
          />
        </span>
      </button>
      <div
        className={classNames(
          'grid',
          'transition-all duration-300 ease-in-out-cubic',
          { 'grid-rows-[0fr] opacity-0': !open },
          { 'grid-rows-[1fr] opacity-100': open }
        )}
      >
        <div className="overflow-hidden">
          <ul className="flex flex-col space-y-spacing-xxs py-spacing-xxs">
            {LANGUAGES.map((lang: string) => (
              <li key={lang} className="flex">
                <a
                  href={
                    currentTranslations?.find((t) => t.locale.includes(lang))
                      ?.href || `/${lang}`
                  }
                  className={classNames('flex px-spacing-xs py-spacing-xxs', {
                    'pointer-events-none': lang === currentLocale
                  })}
                >
                  <Typography
                    variant="small"
                    className={classNames('capitalize', {
                      'text-text-secondary-light dark:text-text-secondary-dark':
                        lang !== currentLocale,
                      'text-text-primary-selected-light': lang === currentLocale
                    })}
                  >
                    {lang}
                  </Typography>
                </a>
              </li>
            ))}
            {/* <li className="flex px-spacing-xs py-spacing-xxs">
              <Typography
                variant="small"
                className="capitalize text-text-secondary-light dark:text-text-secondary-dark"
              >
                It
              </Typography>
            </li>
            <li className="flex px-spacing-xs py-spacing-xxs">
              <Typography
                variant="small"
                className="capitalize text-text-secondary-light dark:text-text-secondary-dark"
              >
                En
              </Typography>
            </li>
            <li className="flex px-spacing-xs py-spacing-xxs">
              <Typography
                variant="small"
                className="capitalize text-text-secondary-light dark:text-text-secondary-dark"
              >
                De
              </Typography>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default LangSelector
