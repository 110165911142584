import { useContext } from 'react'

import { LenisContext } from './LenisProvider'

export const useLenis = () => {
  const context = useContext(LenisContext)

  if (context === undefined) {
    throw new Error('useLenis must be used within a LenisProvider')
  }

  return context
}
