import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { WPMLTranslations } from '@/types'

type State = {
  currentLocale?: string
  currentTranslations: WPMLTranslations
}

type Actions = {
  // eslint-disable-next-line no-unused-vars
  setCurrentLocale: (locale?: string) => void
  // eslint-disable-next-line no-unused-vars
  setCurrentTranslations: (translations: WPMLTranslations) => void
}

const initialState: State = {
  currentLocale: undefined,
  currentTranslations: []
}

const useLocaleStore = create(
  subscribeWithSelector(
    immer<State & Actions>((set) => ({
      ...initialState,
      setCurrentLocale: (locale) => set(() => ({ currentLocale: locale })),
      setCurrentTranslations: (translations) =>
        set(() => ({ currentTranslations: translations }))
    }))
  )
)

export { useLocaleStore }
