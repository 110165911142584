import { useMemo, useRef } from 'react'
import { Transition } from 'react-transition-group'
import { useIsomorphicLayoutEffect } from 'react-use'
import { useGSAP } from '@gsap/react'
import { useGesture } from '@use-gesture/react'
import classNames from 'classnames'
import gsap from 'gsap'

import Spinner from '@/components/base/Spinner'
import SVGIcon from '@/components/base/SVGIcon'
import Typography from '@/components/base/Typography'
import { useLayoutStore } from '@/store/layout'
import { useRouterStore } from '@/store/router'

import { useStore } from '@/contexts/store'
import {
  MASK_TRANSITION_LEAVE_DURATION,
  MASK_TRANSITION_STAGGER_AMOUNT,
  TRANSITION_LEAVE_DURATION
} from '@/contexts/transition'

const VideoIntro = () => {
  const root = useRef(null)
  const active = useRef(true)
  const { strings } = useStore()
  const [needsVideoIntro, videoIntroSkipped, setState] = useLayoutStore(
    (state) => [state.needsVideoIntro, state.videoIntroSkipped, state.setState]
  )

  const duration = useMemo(() => MASK_TRANSITION_LEAVE_DURATION, [])
  const stagger = useMemo(() => MASK_TRANSITION_STAGGER_AMOUNT, [])
  const timeout = useMemo(() => duration + stagger, [duration, stagger])

  const { contextSafe } = useGSAP({ scope: root })

  const handleLeaveTransition = contextSafe(() =>
    gsap
      .timeline()
      .add(
        gsap
          .timeline()
          .to('[data-intro-wrapper]', {
            yPercent: -100,
            duration,
            ease: 'power3.inOut'
          })
          .to(
            '[data-intro-content]',
            {
              yPercent: 70,
              duration,
              ease: 'power3.inOut'
            },
            '<'
          )
      )
      .add(
        gsap.to('[data-intro-primary]', {
          yPercent: -100,
          duration,
          ease: 'power3.inOut'
        }),
        `<+${stagger}`
      )
  )

  useGesture(
    {
      onDrag: ({ swipe }) => {
        const swipeY = swipe[1]
        if (swipeY >= 0) return
        if (active.current) {
          active.current = false
          setState({ needsVideoIntro: false, videoIntroSkipped: true })
        }
      },
      onWheel: ({ direction }) => {
        const directionY = direction[1]
        if (directionY <= 0) return
        if (active.current) {
          active.current = false
          setState({ needsVideoIntro: false, videoIntroSkipped: true })
        }
      }
    },
    {
      enabled: needsVideoIntro,
      target: root
    }
  )

  useIsomorphicLayoutEffect(() => {
    const unsubscribe = useRouterStore.subscribe(
      (state) => state.currentRoute,
      (currentRoute, previousRoute) => {
        const delay =
          previousRoute === undefined ? 0 : TRANSITION_LEAVE_DURATION

        gsap.delayedCall(delay, () => {
          setState({
            needsVideoIntro: !videoIntroSkipped && currentRoute === '/'
          })
        })
      },
      {
        fireImmediately: true
      }
    )

    return () => {
      unsubscribe()
    }
  }, [videoIntroSkipped, setState])

  return (
    <Transition
      in={needsVideoIntro}
      nodeRef={root}
      timeout={timeout * 1000}
      onExit={handleLeaveTransition}
      enter={false}
      unmountOnExit
    >
      <section
        ref={root}
        className="fixed left-0 top-0 z-intro h-full w-full touch-none"
      >
        <div
          className="absolute left-0 top-0 h-full w-full bg-text-overline-light"
          data-intro-primary
        />
        <div
          className="absolute left-0 top-0 h-full w-full overflow-hidden"
          data-intro-wrapper
        >
          <div
            className="absolute left-0 top-0 h-full w-full"
            data-intro-content
          >
            <div
              className={classNames(
                'absolute left-0 top-0 h-full w-full bg-background-primary-dark',
                'after:absolute after:left-0 after:top-0 after:h-full after:w-full after:bg-black-light/40'
              )}
            >
              <video
                preload="none"
                loop
                muted
                autoPlay
                playsInline
                className="absolute left-0 top-0 block h-full w-full object-cover object-center"
              >
                <source
                  src={strings?.introVideo?.mobile.url}
                  type="video/mp4"
                  media="(max-width: 1023px)"
                />
                <source
                  src={strings?.introVideo?.desktop.url}
                  type="video/mp4"
                  media="(min-width: 1024px)"
                />
              </video>
            </div>
            <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
              <SVGIcon
                symbol="large-logo-icon"
                className="h-[9.2rem] w-[15.4rem] md:h-[13.2rem] md:w-[22rem]"
              />
            </div>
            <div className="absolute bottom-spacing-sm left-0 flex w-full items-center justify-center space-x-spacing-xs md:bottom-spacing-lg">
              <Spinner variant="small" />
              <Typography
                variant="small"
                className="text-text-primary-dark pointer:hidden dark:text-text-primary-dark"
              >
                {strings?.swipeToExplore}
              </Typography>
              <Typography
                variant="small"
                className="text-text-primary-dark touch:hidden dark:text-text-primary-dark"
              >
                {strings?.scrollToExplore}
              </Typography>
            </div>
          </div>
        </div>
      </section>
    </Transition>
  )
}

export default VideoIntro
