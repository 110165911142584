import gsap from 'gsap'
import { CustomEase } from 'gsap/dist/CustomEase'
import { Observer } from 'gsap/dist/Observer'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { SplitText } from 'gsap/dist/SplitText'

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger, SplitText, CustomEase, Observer)

  gsap.config({
    nullTargetWarn: false
  })
}
