import {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef
} from 'react'
import { SwitchTransition, Transition } from 'react-transition-group'
import { useGSAP } from '@gsap/react'
import classNames from 'classnames'
import gsap from 'gsap'
import { useRouter } from 'next/router'

import { useLoaderStore } from '@/store/loader'

import {
  TRANSITION_ENTER_DURATION,
  TRANSITION_LEAVE_DURATION
} from '@/contexts/transition'
import MaskTransition, {
  MaskTransitionHandler
} from '@/contexts/transition/components/MaskTransition'

export const TransitionContext = createContext<undefined>(undefined)

const TransitionProvider = ({
  hasHero,
  children
}: PropsWithChildren<{
  hasHero?: boolean
}>) => {
  const router = useRouter()
  const nodeRef = useRef<HTMLElement>(null!)
  const maskRef = useRef<MaskTransitionHandler>(null!)
  const setLoaderState = useLoaderStore((state) => state.setState)

  const { contextSafe } = useGSAP({ scope: nodeRef })

  const handleEnterTransition = contextSafe(() =>
    requestAnimationFrame(() => {
      if (!useLoaderStore.getState().pageLoaded) return
      // console.log(maskRef.current?.leaveTimeline().duration())
      gsap.timeline().add(maskRef.current?.leaveTimeline())
    })
  )

  const handleLeaveTransition = contextSafe(() => {
    setLoaderState({ pageLoaded: false })
    gsap.timeline().add(maskRef.current?.enterTimeline())
  })

  useEffect(() => {
    const unsubscribe = useLoaderStore.subscribe(
      (state) => state.pageLoaded,
      (pageLoaded) => {
        pageLoaded && handleEnterTransition()
      }
    )

    return () => {
      unsubscribe()
    }
  }, [handleEnterTransition])

  const contextValue = useMemo(() => undefined, [])

  return (
    <TransitionContext.Provider value={contextValue}>
      <SwitchTransition mode="out-in">
        <Transition
          appear
          key={router.asPath}
          nodeRef={nodeRef}
          timeout={{
            enter: TRANSITION_ENTER_DURATION * 1000,
            exit: TRANSITION_LEAVE_DURATION * 1000
          }}
          onEnter={handleEnterTransition}
          onExit={handleLeaveTransition}
          unmountOnExit
        >
          <main
            ref={nodeRef}
            className={classNames(
              'static h-full w-full bg-background-primary-light dark:bg-background-primary-dark',
              {
                'pt-[9.6rem] md:pt-[14.3rem]': !hasHero
              }
            )}
          >
            {children}
            <MaskTransition ref={maskRef} />
          </main>
        </Transition>
      </SwitchTransition>
    </TransitionContext.Provider>
  )
}

export default TransitionProvider
