/* eslint-disable import/no-duplicates */
import { ReactElement, ReactNode } from 'react'
import { NextPage } from 'next'
import type { AppContext, AppProps } from 'next/app'
import { default as NextApp } from 'next/app'
import dynamic from 'next/dynamic'

import AgeGate from '@/components/base/AgeGate'
import GoogleTagManager from '@/components/base/GoogleTagManager'
import VideoIntro from '@/components/base/VideoIntro'
import Header from '@/components/layout/Header'
import Theme from '@/components/layout/Theme'
import { transformKeysToCamelCase } from '@/utils/object'
import { pageHasHero } from '@/utils/page'
import { getPromiseFromFetch } from '@/utils/request'
import { API } from '@/constants'
import tracking from '@/base/tracking'

import '@/styles/datepicker.css'
import '@/styles/globals.css'

import '@/base/gsap'

import type { Store } from '@/contexts/store'
import { StoreProvider } from '@/contexts/store'
import { TransitionProvider } from '@/contexts/transition'

const GridHelper = dynamic(() => import('@/components/base/GridHelper'), {
  ssr: false
})
const SVGSprites = dynamic(() => import('@/components/base/SVGSprites'), {
  ssr: false
})

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  // eslint-disable-next-line no-unused-vars
  getLayout?: (page: ReactElement, props: AppProps) => ReactNode
}

tracking()

export default function App({
  Component,
  pageProps,
  menu,
  strings
}: AppProps & { Component: NextPageWithLayout } & Store) {
  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <>
      <GoogleTagManager />
      <StoreProvider menu={menu} strings={strings}>
        {process.env.NODE_ENV !== 'production' ? <GridHelper /> : null}
        <Theme />
        <Header hasHero={pageHasHero(pageProps)} />
        <TransitionProvider hasHero={pageHasHero(pageProps)}>
          {getLayout(<Component {...pageProps} />, pageProps)}
        </TransitionProvider>
        <VideoIntro />
        <AgeGate />
        <SVGSprites />
      </StoreProvider>
    </>
  )
}

App.getInitialProps = async (appContext: AppContext) => {
  const appProps = await NextApp.getInitialProps(appContext)
  const lang = appContext.router.locale

  const { data: menuData } = await getPromiseFromFetch({
    method: 'get',
    url: `${API.MENU_LOCATIONS}?lang=${lang}`
  })

  const { data: stringsData } = await getPromiseFromFetch({
    method: 'get',
    url: `${API.STRINGS}?lang=${lang}`
  })

  return {
    ...appProps,
    strings: transformKeysToCamelCase(stringsData) || {},
    menu:
      Object.keys(menuData).length > 0 ? transformKeysToCamelCase(menuData) : {}
  }
}
