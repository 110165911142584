import { merge } from 'lodash'
import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

export type State = {
  limit: number
  scroll: number
  progress: number
  dimensions: {
    width: number
    height: number
    scrollHeight: number
    scrollWidth: number
    content: HTMLDivElement | null
    wrapper: HTMLDivElement | null
  }
}

export type Actions = {
  // eslint-disable-next-line no-unused-vars
  setState: (state: State) => void
}

const initialState: State = {
  limit: 0,
  scroll: 0,
  progress: 0,
  dimensions: {
    width: 0,
    height: 0,
    scrollHeight: 0,
    scrollWidth: 0,
    content: null,
    wrapper: null
  }
}

const useLenisStore = create(
  subscribeWithSelector(
    immer<State & Actions>((set) => ({
      ...initialState,
      setState: (state) => set((prevState) => merge(prevState, state))
    }))
  )
)

export { useLenisStore }
