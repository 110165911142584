import {
  AnchorHTMLAttributes,
  forwardRef,
  useEffect,
  useMemo,
  useState
} from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'

import Link, { LinkProps } from '@/components/base/Link'
import { /* isExternalLink, */ isExternalLink, stripSlash } from '@/utils/path'

type AnchorLinkProps = AnchorHTMLAttributes<HTMLAnchorElement>
type NextLinkProps = LinkProps

type LinkRouterProps = NextLinkProps | AnchorLinkProps

const LinkRouter = forwardRef<HTMLAnchorElement, LinkRouterProps>(
  ({ className, ...props }, ref) => {
    const { asPath, locale } = useRouter()
    const [needsExternalLink, setNeedsExternalLink] = useState(false)

    const active = useMemo(
      () =>
        stripSlash(props.href as string) === stripSlash(`/${locale}${asPath}`),
      [props.href, asPath, locale]
    )

    const variantClasses = classNames(
      'transition-opacity duration-300 ease-out-cubic pointer:hover:opacity-40',
      '[&>*]:transition-opacity [&>*]:duration-300 [&>*]:ease-out-cubic',
      {
        'pointer-events-none cursor-default [&>*]:opacity-40': active
      }
    )

    useEffect(() => {
      setNeedsExternalLink(isExternalLink(props.href as string) || false)
    }, [props.href])

    return needsExternalLink ? (
      <a
        ref={ref}
        target="_blank"
        className={classNames(variantClasses, className)}
        {...(props as AnchorLinkProps)}
      />
    ) : (
      <Link
        ref={ref}
        className={classNames(variantClasses, className)}
        {...(props as NextLinkProps)}
      />
    )
  }
)

export default LinkRouter
