import { useContext } from 'react'
import { StoreApi, useStore as useZustandStore } from 'zustand'

import { Store, StoreContext } from './StoreProvider'

// eslint-disable-next-line no-unused-vars
export const useStore = (selector?: any) => {
  const store = useContext(StoreContext)

  if (!store) {
    throw new Error('useStore must be used within a StoreProvider')
  }

  return useZustandStore<StoreApi<Store>, Store>(store, selector)
}
