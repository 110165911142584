import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react'
import { useGSAP } from '@gsap/react'
import gsap from 'gsap'

import {
  MASK_TRANSITION_ENTER_DURATION,
  MASK_TRANSITION_LEAVE_DURATION,
  MASK_TRANSITION_STAGGER_AMOUNT
} from '@/contexts/transition'

export type MaskTransitionHandler = {
  enterTimeline: () => gsap.core.Timeline
  leaveTimeline: () => gsap.core.Timeline
}

const MaskTransition = forwardRef<MaskTransitionHandler>((_, ref) => {
  const root = useRef<HTMLDivElement>(null)
  const length = useMemo(() => 127.97530364990234, [])

  const { contextSafe } = useGSAP({ scope: root })

  const enterTimeline = contextSafe(() => {
    return gsap
      .timeline()
      .set(root.current, { display: 'block' })
      .add(
        gsap.timeline().fromTo(
          ['[data-mask-primary]', '[data-mask-secondary]'],
          { yPercent: 100 },
          {
            yPercent: 0,
            duration: MASK_TRANSITION_ENTER_DURATION,
            stagger: MASK_TRANSITION_STAGGER_AMOUNT,
            ease: 'power3.in',
            force3D: true
          }
        )
      )
      .add(
        gsap
          .timeline()
          .fromTo(
            '[data-mask-transparent]',
            { yPercent: 100 },
            {
              yPercent: 0,
              duration: MASK_TRANSITION_ENTER_DURATION,
              ease: 'power3.in',
              force3D: true
            }
          )
          .fromTo(
            '[data-mask-logo]',
            { yPercent: -100 },
            {
              yPercent: 0,
              duration: MASK_TRANSITION_ENTER_DURATION,
              ease: 'power3.in',
              force3D: true
            },
            '<'
          ),
        `<+${MASK_TRANSITION_STAGGER_AMOUNT}`
      )
    /* .fromTo(
        '[data-mask-logo-circle]',
        { strokeDashoffset: length },
        {
          strokeDashoffset: 0,
          duration: MASK_TRANSITION_ENTER_DURATION,
          ease: 'power3.inOut'
        },
        '<'
      ) */
  })

  const leaveTimeline = contextSafe(() => {
    return (
      gsap
        .timeline()
        /* .fromTo(
        '[data-mask-logo-circle]',
        { strokeDashoffset: 0 },
        {
          strokeDashoffset: -length,
          duration: MASK_TRANSITION_ENTER_DURATION,
          ease: 'power3.inOut'
        },
        '<'
      ) */
        .add(
          gsap
            .timeline()
            .fromTo(
              '[data-mask-transparent]',
              { yPercent: 0 },
              {
                yPercent: -100,
                duration: MASK_TRANSITION_LEAVE_DURATION,
                ease: 'power3.out',
                force3D: true
              }
            )
            .fromTo(
              '[data-mask-logo]',
              { yPercent: 0 },
              {
                yPercent: 100,
                duration: MASK_TRANSITION_LEAVE_DURATION,
                ease: 'power3.out',
                force3D: true
              },
              '<'
            ),
          '<'
        )
        .add(
          gsap.timeline().fromTo(
            ['[data-mask-secondary]', '[data-mask-primary]'],
            { yPercent: 0 },
            {
              yPercent: -100,
              duration: MASK_TRANSITION_LEAVE_DURATION,
              stagger: MASK_TRANSITION_STAGGER_AMOUNT,
              ease: 'power3.out',
              force3D: true
            }
          ),
          '<'
        )

        .set(root.current, { display: 'none' })
    )
  })

  useImperativeHandle(
    ref,
    () => ({
      leaveTimeline,
      enterTimeline
    }),
    [enterTimeline, leaveTimeline]
  )

  return (
    <div ref={root} className="fixed left-0 top-0 z-transition h-screen w-full">
      <div
        className="absolute left-0 top-0 h-full w-full bg-text-overline-light"
        data-mask-primary
      />
      <div
        className="absolute left-0 top-0 h-full w-full bg-background-primary-dark"
        data-mask-secondary
      />
      <div
        className="absolute left-0 top-0 h-full w-full overflow-hidden"
        data-mask-transparent
      >
        <div className="absolute left-0 top-0 h-full w-full" data-mask-logo>
          <svg
            viewBox="0 0 42 42"
            className="absolute left-1/2 top-1/2 h-[4.8rem] w-[4.8rem] -translate-x-1/2 -translate-y-1/2"
            data-mask-logo-svg
          >
            <circle
              id="circle"
              r="20.5"
              cx="21"
              cy="21"
              strokeWidth="1"
              strokeLinecap="round"
              strokeDashoffset={0}
              strokeDasharray={length}
              className="fill-transparent stroke-white-light"
              data-mask-logo-circle
            />
          </svg>
          <svg
            viewBox="0 0 42 42"
            className="absolute left-1/2 top-1/2 h-[4.8rem] w-[4.8rem] -translate-x-1/2 -translate-y-1/2"
          >
            <path
              d="M24.4946 21.1262C24.4946 19.3578 25.1683 18.0104 26.4315 16.9157C27.6104 15.9894 29.1262 15.4841 30.9788 15.4841C31.3999 15.4841 31.8209 15.5683 32.242 15.6526C32.6631 15.7368 33.4209 15.9052 34.1788 16.1578C34.0104 17.0841 33.9262 17.842 33.9262 18.4315H33.4209V17.6736C33.4209 17.3368 33.4209 17.1683 33.3367 17.1683C33.1683 16.9999 32.9999 16.8315 32.6631 16.6631C32.3262 16.4947 31.9894 16.4104 31.6525 16.3262C31.2315 16.242 30.8946 16.242 30.4736 16.242C28.9578 16.242 27.863 16.7473 27.0209 17.7578C26.3473 18.5999 26.0104 19.6947 26.0104 20.9578C26.0104 21.7999 26.1788 22.5578 26.4315 23.1473C26.6841 23.7368 27.1052 24.3262 27.6104 24.6631C28.1157 25.0841 28.6209 25.3368 29.2104 25.5052C29.7999 25.6736 30.3894 25.7578 30.9788 25.7578C32.242 25.6736 33.2525 25.3368 34.0946 24.8315L34.1788 24.9999L33.7578 25.7578C33.7578 25.7578 33.5052 25.842 33.2525 26.0104C32.9157 26.0947 32.5788 26.2631 32.0736 26.3473C31.5683 26.4315 31.063 26.5157 30.4736 26.5999C29.5473 26.5999 28.6209 26.4315 27.6946 26.0947C26.7683 25.7578 25.9262 25.1683 25.3367 24.3262C24.8315 23.4841 24.4946 22.4736 24.4946 21.1262Z"
              fill="white"
            />
            <path
              d="M18.6 29.7999H20.1158L20.7895 5.04201C20.7895 4.78938 21.2106 4.78938 21.2106 5.04201L21.8842 29.7999H23.4L23.6527 29.6315H24.7474V30.7262H23.6527L23.4 30.5578H21.5474L21.379 35.442C21.7158 35.6104 21.8842 35.9473 21.8842 36.2841C21.8842 36.7894 21.4632 37.2104 20.9579 37.2104C20.4527 37.2104 20.0316 36.7894 20.0316 36.2841C20.0316 35.8631 20.2842 35.6104 20.5369 35.442L20.3685 30.5578H18.5158L18.2632 30.7262H17.1685V29.6315H18.2632L18.6 29.7999Z"
              fill="#B58B1E"
            />
            <path
              d="M12.5368 24.4947V17.8421C12.5368 17.3368 12.5368 16.8315 12.4526 16.3263H10.0105C9.75786 16.3263 9.58944 16.4105 9.58944 16.5789C9.58944 16.6631 9.50523 16.9157 9.50523 17.3368C9.50523 17.7579 9.50523 18.0105 9.42102 18.0947H8.91575C8.91575 17.1684 8.91575 16.3263 8.83154 15.6526C10.2631 15.7368 11.4421 15.7368 12.5368 15.7368C13.9684 15.7368 15.6526 15.6526 17.6736 15.6526C17.6736 15.821 17.6736 16.1579 17.5894 16.5789C17.5894 17 17.5052 17.421 17.5052 17.6736C17.5052 17.7579 17.5052 17.9263 17.5052 18.0947H17C17 18.0947 17 17.7579 16.9158 17.3368C16.9158 16.9157 16.8315 16.6631 16.8315 16.5789C16.7473 16.4947 16.5789 16.4105 16.4105 16.4105H13.9684C13.9684 16.5789 13.9684 17 13.9684 17.7579V24.5789C13.9684 25 14.0526 25.3368 14.1368 25.5052C14.221 25.5894 14.221 25.5894 14.3894 25.6736C14.4736 25.6736 14.6421 25.7579 14.8105 25.7579C14.9789 25.7579 15.2315 25.7579 15.4842 25.7579V26.2631C15.2315 26.2631 14.8947 26.2631 14.3894 26.2631C13.8842 26.2631 13.5473 26.2631 13.2947 26.2631C13.0421 26.2631 12.7052 26.2631 12.1158 26.2631C11.6105 26.2631 11.1894 26.2631 10.9368 26.2631V25.7579C10.9368 25.7579 11.1894 25.7579 11.4421 25.7579C11.6947 25.7579 11.8631 25.7579 11.9473 25.6736C12.0315 25.6736 12.2 25.5894 12.2 25.5894C12.3684 25.5052 12.4526 25.1684 12.5368 24.4947Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
  )
})

export default MaskTransition
