import {
  isAndroid,
  isIPad13,
  isIPhone13,
  isMobileSafari,
  isTablet,
  isWinPhone
} from 'react-device-detect'

function isTouchDevice() {
  if (typeof window === 'undefined') return false
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
  function mq(query: string) {
    return typeof window !== 'undefined' && window.matchMedia(query).matches
  }

  // eslint-disable-next-line no-undef
  if (
    'ontouchstart' in window ||
    //@ts-ignore
    (window?.DocumentTouch && document instanceof DocumentTouch)
  )
    return true
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('') // include the 'heartz' - https://git.io/vznFH
  return mq(query)
}

export const isTouch =
  isAndroid ||
  isIPad13 ||
  isIPhone13 ||
  isWinPhone ||
  isMobileSafari ||
  isTablet ||
  isTouchDevice()
